<template>
  <div>
    <el-container>
        <el-header class="DetailsHeader">
            <div class="IndexTab" @click="ToIndex">首页</div>
            <div class="ZoneTab" @click="ToZone">广场</div>
            <div class="Avatar">
                <img :src="icon">
                <div class="InfoPanel">
                    <div class="Username">
                        {{nickname}}
                    </div>
                    <div class="InfoBanner">
                        <div class="UserCenter" @click="ToUserCenter">
                            <i class="el-icon-user-solid mark"></i>个人中心
                        </div>
                        <div class="ContentMP" @click="ToMp">
                            <i class="el-icon-s-management mark"></i>内容管理
                        </div>
                    </div>
                </div>
            </div>
            <div class="PublishBlog" @click="PublishBlog">
                <i class="el-icon-edit"></i>发布
            </div>
        </el-header>
        <el-main class="Context" v-loading="loading">
            <div class="DetailsBanner">
                <div class="BannerLeft">
                    <div class="Top">
                        <div class="TopAvatar" @click="ToUserSpace(blogdetails.userId)"><img :src="blogdetails.icon"></div>
                        <div class="TopNickName">{{blogdetails.nickname}}</div>
                    </div>
                </div>
                <div class="BannerRight">
                    <div class="BlogTitle">
                        {{blogdetails.title}}
                    </div>
                    <div class="BlogTips">
                        <div class="TipsUp">
                            <div class="TipsUp-Name">{{blogdetails.nickname}}</div>
                            <div class="TipsUp-Time"><i class="el-icon-time"></i>于{{blogdetails.createTime}}发布</div>
                        </div>
                        <div class="TipsDown">
                            <div class="TipsDown-Name">文章标签：</div>
                            <div class="Tags" v-for="(item,index) in blogdetails.keywords" :key="index">
                                {{item}}
                            </div>
                        </div>
                    </div>
                    <div class="BlogContext" >
                        <div v-html="htmlStr" class="markdown-body"></div>
                        <!-- {{blogdetails.content}} -->
                    </div>
                    <div class="BlogBottom">
                        <div class="BottomAvatar"><img :src="blogdetails.icon"></div>
                        <div class="BottomName">{{blogdetails.nickname}}</div>


                        <div class="BottomLike" v-if="!LikeInfo.hasLike" @click.stop="_isLike(LikeInfo.id)">
                            <i class="iconfont icon-dianzan" ></i>
                            <span class="count">{{LikeInfo.likeCount}}</span>赞
                        </div>
                        <div v-else class="BottomLike" @click.stop="_unLike(LikeInfo.id)">
                            <i class="iconfont icon-dianzan1" ></i>
                            <span class="count">{{LikeInfo.likeCount}}</span>赞
                        </div>


                        <div class="BottomComments" @click="OpenCommentsBox">
                            <i class="el-icon-chat-dot-square"></i>
                            <span class="count">{{commentsCount}}</span>
                        </div>
                    </div>
                    <el-drawer
                    :title="'评论   '+commentsCount"
                    :visible.sync="CommentsBoxVisible"
                    :size='size'
                    class="CommentDrawer">
                        <ArticleComments :id="blogdetails.id" @RefreshCommentsCount="GetArticleComment"></ArticleComments>
                    </el-drawer>
                </div>
            </div>
        </el-main>
        <div class="CodeBox">
            <CodeEditorArticle />
            <div class="CodeBar">
            <i class="iconfont icon-jiantou_xiangyouliangci RightBtn"></i>
            </div>
        </div>
    </el-container>
    <div id="outerbox">
        <div id="innerbox">
            <img id="bigimg" src="">
        </div>
    </div>
  </div>
</template>

<script>
import ArticleComments from '../comments/ArticleComments.vue'
import { marked } from "marked"
import hljs from "highlight.js";
import 'github-markdown-css';
import 'highlight.js/styles/atelier-plateau-dark.css'//atelier-dune-dark   atelier-plateau-dark
import { isLike,unLike } from "../../api/like";

const renderer = new marked.Renderer()
marked.setOptions({
    renderer: renderer, 
    gfm: true,
    pedantic: false,
    sanitize: false,
    tables: true,
    breaks: false,
    smartLists: true,
    smartypants: false,
    highlight: function (code) {
            return hljs.highlightAuto(code).value;
    }
}); 

import {GetBlogDetails} from '../../api/blog.js'
import {GetPostComments} from  '../../api/comments.js'
import CodeEditorArticle from '../../views/CodeEditorArticle.vue'
export default {
    name:'ArticleDetails',
    props:['id'],
    components:{
        ArticleComments,
        CodeEditorArticle
    },
    data(){
        return{
            icon : window.localStorage.getItem('usericon'),
            nickname : window.localStorage.getItem('username'),
            blogdetails:[],
            htmlStr:'',
            LikeInfo:'',
            loading:true,

            comments:[],
            commentsCount:0,
            CommentsBoxVisible:false,
            size:'400px'
        }
    },
    created(){
        $('#outerbox').hide()
        setTimeout(() => {
            this.BlogDetails()
            this.GetLikeInfo()
            this.GetArticleComment()
            this.loading = false
        }, 500);
    },
    mounted(){
        this.isShow() 
    },
    computed: {
        
    },
    methods:{
        
        isShow(){
            $('.RightBtn').click(function(e){
            if($('.CodeBox').offset().left == 0){
                $('.CodeBox').animate({left:'-615px'},500)
            }else{
                $('.CodeBox').animate({left:'0px'},500)
            }
            })
        },
        OpenCommentsBox(){
            this.CommentsBoxVisible = true
        },
        async GetArticleComment(){
            this.comments = await GetPostComments(this.id)
            this.commentsCount = this.comments.data.list.length
            // console.log(this.commentsCount)
            // console.log('子组件调用成功了')
        },
        async _isLike(id){
            let res = await isLike(id)
            this.$message({
                message: '点赞成功',
                type: 'success'
            });
            this.GetLikeInfo()
        },
        async _unLike(id){
            let res = await unLike(id)
            this.$message({
                message: '取消点赞成功',
                type: 'success'
            });
            this.GetLikeInfo()
        },
        async GetLikeInfo(){
            let res = await GetBlogDetails(this.id)
            let Data = {
                "hasLike":res.data.hasLike,
                "likeCount":res.data.likeCount,
                "id":res.data.id
            }
            this.LikeInfo=Data
        },
        ToUserSpace(userid){
            let MyUserid = window.localStorage.getItem('userid')
            if(userid == MyUserid){
                this.$router.push('/myspace')
            }else{
                window.sessionStorage.setItem('vnickname',this.blogdetails.nickname)
                window.sessionStorage.setItem('vicon',this.blogdetails.icon)
                this.$router.push(`/v_space/${userid}`)
            }
        },
        async BlogDetails(){
            let res = await GetBlogDetails(this.id)
            this.blogdetails = res.data
            // console.log(this.blogdetails)
            this.blogdetails.content = marked(this.blogdetails.content)
            let html = this.blogdetails.content
            html = html.split('<img')
            let i=0
            for(i;i<html.length-1;i++){
                this.htmlStr += html[i] + '<img style="width:100%"; onclick="Clickimg(this)"'
            }
            this.htmlStr = this.htmlStr + html[html.length-1]
            // console.log(this.htmlStr)
        },
        ToIndex(){
            this.$router.push({path:'/'})
        },
        ToUserCenter(){
            this.$router.push({path:'/account/usercenter'})
        },
        ToMp(){
            this.$router.push({path:'/mp_blog'})
        },
        PublishBlog(){
            this.$router.push({path:'/md_article'})
        },
        ToZone(){
            this.$router.push({path:'/zone'})
        }
    }
}
// 9.08更新 start
var scaling = 1;
window.scaling = scaling
function Clickimg(e){
    e=$(e)
    showBigImg('#outerbox', '#innerbox', '#bigimg', e)
}
function showBigImg(outerbox, innerbox, bigimg, _this) {
    var src = _this.attr('src'); //获取当前点击图片元素的src属性
    $(bigimg).attr('src', src); //设置#bigimg元素的src属性值
    var windowW = $(window).width(); //获取当前窗口宽度
    var windowH = $(window).height(); //获取当前窗口高度
    var realW = ''//  获取图片真实宽度
    var realH = '' // 获取图片真实高度
    var imgW, imgH;
    var img = new Image()
    img.src = src
    if(img.complete){
        realW = img.width
        realH = img.height
    }else{
        img.onload = function(){
            realW = img.width
            realH = img.height
        }
    }
    var scale = 0.8; //当图片的真实宽高大于窗口的宽高时要进行的缩放尺寸
    if (realH > windowH * scale) { //判断图片高度
        imgH = windowH * scale; //如大于窗口高度，图片高度进行缩放
        imgW = imgH / realH * realW; //等比例缩放宽度
        if (imgW > windowW * scale) { //如宽度仍大于窗口宽度
            imgW = windowW * scale; //再对宽度进行缩放
        }
    } else if (realW > windowW * scale) { //如图片高度合适，判断图片宽度
        imgW = windowW * scale; //图片宽度进行缩放
        imgH = imgW / realW * realH; //等比例缩放高度
    } else { //如图片真实宽高都符合要求，宽高不变
        imgW = realW;
        imgH = realH;
    }
    $(bigimg).css('width', imgW); //以最终的宽度对图片进行缩放
    var w = (windowW - imgW) / 2; //计算图片与窗口的左右边距
    var h = (windowH - imgH) / 2; //计算图片与窗口的上下边距
    // $(innerbox).css({
    //     'top': h,
    //     'left': w
    // });
    $(outerbox).css(
        'display','flex'
    );
    $(outerbox).fadeIn('fast'); //淡入显示
    $('body').css('overflow-y', 'hidden');
     // IE9, Chrome, Safari, Opera  -- 鼠标滚动监听
    $('#bigimg').on("mousewheel", MouseWheelHandler);
    // Firefox	-- 鼠标滚动监听
    $('#bigimg').on("DOMMouseScroll", MouseWheelHandler);
    $(outerbox).click(function() { //再次点击淡出消失弹窗
        $(this).fadeOut('fast');
        // 解绑 鼠标滚轮监听事件
        $('#bigimg').off("mousewheel");
        $('#bigimg').off("DOMMouseScroll");
        // 重置
        scaling = 1;
        $('#bigimg').css('transform', 'scale(1)');
        $('body').css('overflow-y', 'auto');
    })
}
// 鼠标滚轮放大缩小
function MouseWheelHandler(event) {
        var type = event.type;
        if (type == 'DOMMouseScroll' || type == 'mousewheel') {
            event.delta = (event.originalEvent.wheelDelta) ? event.originalEvent.wheelDelta / 120 : -(event.detail || 0) / 3;
        }
        if (event.delta == 1 || event.delta > 0) { //上滚 -- 放大
            zoomImg('#bigimg', 'add')
        } else if (event.delta == -1 || event.delta < 0) { //下滚 -- 缩小
            zoomImg('#bigimg', 'cut')
        } else {
            console.log("鼠标滚轮放大缩小状态获取失败")
        }
    }
// 缩放图片比例
function zoomImg(img, type) {
    if (type == 'add') {
        scaling += 0.1;
    } else {
        if (scaling < 0.5) { //控制图片缩小到一定大小
            return
        }
        scaling -= 0.1;
    }
    $(img).css('transform', `scale(${scaling})`);
}
window.Clickimg = Clickimg
window.showBigImg = showBigImg
// 9.08更新 end
</script>

<style scoped>
.DetailsHeader{
    position: relative;
    height: 40px !important;
    width: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 20;
}
.Context{
    /* background-color: #ebf3f9; */
}
.IndexTab,.ZoneTab{
    height: 75%;
    width: 60px;
    font-size: 14px;
    line-height: 30px;
    cursor: pointer;
}
.IndexTab:hover,.ZoneTab:hover{
    background-color: #dddddd;
}
.Avatar{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    right: 130px;
    width: 40px;
    height: 100%;
    border-radius: 20px;
    cursor: pointer;
}
.Avatar img{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
}
.Avatar:hover img{
    margin-top: 10px;
    transform:scale(1.5);
    z-index: 20;
}
.Avatar:hover .InfoPanel{
    display: block;
}
.InfoPanel{
    position: absolute;
    display: none;
    width: 200px;
    top: 40px;
    height: 300px;
    padding: 20px 0px 0px 0px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
}
.Username{
    width: 100%;
    height: 20px;
    padding: 10px 0px 20px 0px;
    font-size: 15px;
    font-weight: 600;
    border-bottom: 1px solid #e8e8ed;
    font-family: 'PingFang SC','SF Pro Display','Microsoft YaHei',Roboto,Noto,Arial,sans-serif;
}
.InfoBanner{
    padding: 5px 0px 0px 0px ;
}
.UserCenter,.ContentMP{
    padding: 5px 0px 0px 10px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    text-align: left;
    color: gray;
}
.mark{
    padding: 0px 8px 0px 0px;
}
.UserCenter:hover,.ContentMP:hover{
    background-color: #dddddd;
}
.PublishBlog{
    font-size: 14px;
    position: absolute;
    right: 20px;
    top: 4px;
    width: 60px;
    height: 80%;
    line-height: 32px;
    border-radius: 20px;
    color: white;
    cursor: pointer;
    background-color: #fc5531;
}
.DetailsBanner{
    width: 80%;
    margin: 0 auto;
    /* padding: 0px 50px; */
    height: 100%;
    display: flex;
}
.BannerLeft{
    flex: 0 0 25%;
    height: 100%;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
}
.BannerRight{
    flex: 1;
    margin-left: 10px;
    height: 100%;
    border-radius: 4px;
    padding: 10px 20px 0px 20px;
    background-color: rgb(255, 255, 255);
}
.Top{
    position: relative;
    display: flex;
    /* align-items: center; */
    /* justify-content: center; */
    height: 50px;
    width: 100%;
}
.TopAvatar{
    width: 50px;
    height: 100%;
    border-radius: 25px;
    cursor: pointer;
}
.TopAvatar img{
    position: absolute;
    left: 0;
    border-radius: 25px;
    width: 50px;
    height: 100%;
}
.TopNickName{
    width: auto;
    height: 25px;
    font-size: 14px;
    line-height: 25px;
    padding: 25px 0px 0px 5px;
}
.BlogTitle{
    height: auto;
    line-height: 50px;
    font-size: 28px;
    font-weight: 600;
    text-align: left;
}
.BlogTips{
    height: 60px;
    width: 100%;
    background-color: #f8f8f8;
    border-radius: 4px;
}
.TipsUp{
    display: flex;
    height: 50%;
    width: 100%;
}
.TipsUp-Name{
    padding:5px 0px 0px 5px;
    width: auto;
    font-size: 14px;
    color: rgb(47, 47, 47);
    line-height: 30px;
}
.TipsUp-Time{
    padding:5px 0px 0px 10px;
    width: auto;
    font-size: 14px;
    color: gray;
    line-height: 30px;
}
.TipsDown{
    display: flex;
    align-items: center;
    height: 50%;
    width: 100%;
}
.TipsDown-Name{
    padding:0px 0px 0px 5px;
    font-size: 15px;
    line-height: 30px;
    color: rgb(153, 153, 153);
}
.Tags{
    font-size: 12px;
    height: 20px;
    width: auto;
    line-height: 20px;
    padding: 0px 4px;
    background-color: white;
    border-radius: 4px;
    margin-right: 5px;
}
.BlogContext{
    padding: 10px 0px 20px 0px;
    text-align: left;
    font-size: 14px;
}
.BlogContext > img{
    width: 100% !important;
}
.BlogBottom{
    position: relative;
    height: 30px;
    padding: 10px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
}
.BottomAvatar{
    position: relative;
    width: 30px;
    height: 100%;
    /* background-color: #dddddd; */
    border-radius: 15px;
}
.BottomAvatar img{
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    border-radius: 15px;
}
.BottomName{
    height: 100%;
    width: auto;
    padding: 0px 0px 0px 5px;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
}
.BottomLike{
    position: absolute;
    right: 70px;
    width: 60px;
    line-height: 25px;
    /* background-color: #ededed; */
    height: 50%;
    font-size: 14px;
    cursor: pointer;
}
.count{
    padding: 0px 5px;
}
.BottomComments{
   position: absolute;
    right: 10px;
    width: 50px;
    /* background-color: #ededed; */
    height: 50%; 
    font-size: 16px;
    line-height: 25px;
    cursor: pointer;
}
.ArticleComment{
    font-size: 14px;
    padding: 10px 0px 10px 10px;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    background-color: #ffffff;
    margin: 10px 0px;
}
/* 代码块高亮 */
/* pre {position: relative;margin-bottom: 24px;border-radius: 3px;border: 1px solid #C3CCD0;background: #FFF;overflow: hidden;}
code {display: block;padding: 12px 24px;overflow-y: auto;font-weight: 300;font-family: Menlo, monospace;}
code.has-numbering {margin-left: 21px;}
.pre-numbering {position: absolute;top: 0;left: 0;width: 20px;padding: 12px 2px 12px 0;border-right: 1px solid #C3CCD0;border-radius: 3px 0 0 3px;background-color: #EEE;text-align: right;font-family: Menlo, monospace;font-size: 0.8em;color: #AAA;} */
.CommentDrawer{
    font-size: 14px;
    text-align: left;
}
section.el-drawer__body::-webkit-scrollbar {
    width: 5px;
    background: #F5F5F5;
}
section.el-drawer__body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
    background-color: #d9d9d9;
}
section.el-drawer__body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #F5F5F5;
}


.CodeBox{
  position: fixed;
  width: 630px;
  height: 350px;
  display: flex;
  top: 30%;
  left: -615px;
}
.CodeBar{
  width: 15px;
  z-index: 99;
}
.icon-jiantou_xiangyouliangci{
  color: #787878;
  line-height: 330px;
  font-size: 30px !important;
  cursor: pointer;
}
/* 9.08 */
#outerbox {
	width:100%;
	height:100%;
	display:none;
	position:fixed;
	top:0;
	left:0;
	background:rgba(0,0,0,0.6);
	z-index:9;
    align-items: center;
    justify-content: center;
}
#innerbox {
	position:absolute;
	z-index:99;
}
#innerbox #bigimg {
	cursor:grab;
	border:5px solid #ffffff;
	border-radius:10px;
}
/* 9.08 */
</style>