<template>
  <div class="CommentBanner">
    <div class="Comment-edit-box">
      <div class="Comment-user-avatar">
        <img :src="usericon">
      </div>
      <div class="Comment-box">
        <textarea class="Comment-content" maxlength="1000" v-model="content" placeholder="欢迎高质量的评论"></textarea>
        <div class="Comment-operator-box">
          <div class="Comment-operator-l">还能输入<em>{{contentlength-content.length}}</em>个字符</div>
          <div class="Comment-operator-e">
            <i class="iconfont icon-biaoqing" @click="OpenEmojiBox"></i>
            <div class="EmojiTipBox">
              <i class="iconfont icon-xiaosanjiaoup"></i>
              插入表情
            </div>
            <div class="EmojiBox" :style="{display:emojivisible}">

              <div class="Emoingbox" v-for="(item,index ) in emojis" :key="index" @click="AddEmoji(item.text)">
                {{item.text}}
              </div>
            </div>
          </div>
          <div class="Comment-operator-s" @click="SendContent">评论</div>
        </div>
      </div>
    </div>
    <div class="Comment-list">
      <div class="Comment-list-item" v-for="(item,index) in commentsList" :key="index">
        <div class="Comment-list-item-useravatar">
          <img :src="item.icon">
        </div>
        <div class="Comment-list-item-info">
          <div class="Comment-item-userinfo">
            <div class="Comment-name">{{item.nickname}}</div>
            <div class="Comment-time">{{item.createTime}}</div>
          </div>
          <div class="Comment-item-details">
            {{item.content}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {GetPostComments,AddPostComments} from '../../api/comments.js'
export default {
    name:'ArticleComments',
    props:['id'],
    data(){
      return{
        usericon:window.localStorage.getItem('usericon'),
        content:'',
        contentlength:1000,
        emojivisible:'none',
        emojimark:1,
        emojis : [
          '😀', '😄', '😅', '🤣', '😂', '😉', '😊', '😍', '😘', '😜',
          '😝', '😏', '😒', '🙄', '😔', '😴', '😷', '🤮', '🥵', '😎',
          '😮', '😰', '😭', '😱', '😩', '😡', '💀', '👽', '🤓', '🥳',
          '😺', '😹', '😻', '🤚', '💩', '👍', '👎', '👏', '🙏', '💪',
          '🤡','🤏'
        ],
        commentsList:[]
      }
    },
    created(){
      this.emojis = this.emojis.map(emoji => ({text: emoji})),
      this.GetCommentsList()
    },
    watch:{
      content(){
        if(this.content.length>1000){
          this.content=String(this.content).slice(0,1000)
          // console.log(this.content.length)
        }
      }
    },
    methods:{
      OpenEmojiBox(){
        if(this.emojimark == 1){
          this.emojivisible = ''
          this.emojimark = 0
        }else{
          this.emojivisible = 'none'
          this.emojimark = 1
        }
      },
      AddEmoji(emoji){
        this.content = this.content + emoji
        // console.log(this.content)
      },
      SendContent(){
        if(this.content.length){
          let commentData = {
            "content":this.content,
            "postsId":this.id
          }
          AddPostComments(commentData,this.id)
          setTimeout(()=>{
            this.$message({
              message: '发表评论成功',
              type: 'success'
            });
            this.content = ''
            this.emojivisible = 'none'
            this.GetCommentsList()
            this.$emit('RefreshCommentsCount')
          },100)
        }else{
          this.$message({
            message: '评论内容不能为空!',
            type: 'warning'
          });
        }
      },
      async GetCommentsList(){
        let res = await GetPostComments(this.id)
        this.commentsList = res.data.list
        // console.log('评论列表',this.commentsList)
      }
    }


}
</script>

<style scoped>
.CommentBanner{
    font-size: 14px;
}

.Comment-edit-box{
  width: 100%;
  height: auto;
  display: flex;
}
.Comment-user-avatar{
  width: 30px;
  height: 100%;
  margin-right: 5px;
}
.Comment-user-avatar img{
  width: 100%;
  height: 30px;
  border-radius: 15px;
}
.Comment-box{
  flex: 1;
  height: 100%;
  padding: 10px;
  border-radius: 4px;
  position: relative;
  background: rgba(245,246,247,0.8);
}
.Comment-content{
  width: 100%;
  height: 60px;
  border: none;
  resize:none;
  background: rgba(248,249,251,0.8);
  box-sizing:border-box;
  font-size: 14px;
  /* padding: 0 10px; */
  line-height: 20px;
  font-family:"SF Pro Display",Roboto,Noto,Arial,"PingFang SC","Hiragino Sans GB","Microsoft YaHei",sans-serif;
  border-top-left-radius:8px;
  border-top-right-radius:8px;
  outline: none;
}
.Comment-content::-webkit-scrollbar{
  width: 3px;
  background: #F5F5F5;
}
.Comment-content::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #d9d9d9;
}
.Comment-content::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #F5F5F5;
}
.Comment-operator-box{
  height: 30px;
  /* padding: 5px 0; */
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
}
.Comment-operator-l{
  font-size: 12px;
  color: #999aaa;
}
.Comment-operator-l em{
  color: #222226;
  margin: 0 4px;
  font-style: normal;
}
.Comment-operator-e{
  position: absolute;
  right: 60px;
  width: 20px;
  height: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon-biaoqing{
  font-size: 20px;
  cursor: pointer;
  z-index: 40;
}
.Comment-operator-e:hover .EmojiTipBox{
  display: block;
}
.EmojiTipBox{
  display: none;
  position: absolute;
  top: 24px;
  width: 70px;
  height: 15px;
  border-radius: 4px;
  color: white;
  text-align: center;
  background: #000000;
  font-size: 12px;
  line-height: 15px;
  z-index: 30;
}
.icon-xiaosanjiaoup{
  color: black;
  position: absolute;
  top: -12px;
  left: 22px;
  font-size: 25px;
}
.EmojiBox{
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  top: 30px;
  width: 270px;
  right: -25px;
  height: 250px;
  padding: 10px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);
  cursor: pointer;
}
.Emoingbox{
  /* position: absolute; */
  width: 30px;
  height: 30px;
  text-align: center;
  user-select: none;
}
.Emoingbox img{
  width: 100%;
}
.Comment-operator-s{
  position: absolute;
  right: 5px;
  width: 45px;
  height: 80%;
  text-align: center;
  background: #fc5531;
  border-radius: 15px;
  color: white;
  line-height: 24px;
  cursor: pointer;
}
.Comment-list{
  padding: 20px 10px 0px 0px;
  width: 100%;
  height: auto;
  /* background: #999aaa; */
}
/* .Comment-list::-webkit-scrollbar{
  width: 3px;
  background: #F5F5F5;
}
.Comment-list::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #d9d9d9;
}
.Comment-list::-webkit-scrollbar-track{
  border-radius: 10px;
  background-color: #F5F5F5;
} */
.Comment-list-item{
  width: 100%;
  height: auto;
  display: flex;
  margin-bottom: 20px;
}
.Comment-list-item-useravatar{
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.Comment-list-item-useravatar img{
  width: 100%;
  height: 30px;
  border-radius: 15px;
}
.Comment-list-item-info{
  flex: 1;
  height: 100%;
}
.Comment-item-userinfo{
  width: 100%;
  height: 20px;
  display: flex;
  /* background: #fc5531; */
}
.Comment-name{
  flex: 1;
  height: 100%;
  text-align: left;
  color: #777888;
}
.Comment-time{
  flex: 1;
  text-align: right;
  color: #777888;
}
.Comment-item-details{
  line-height: 20px;
  padding: 5px 0px;
  /* background-color: aquamarine; */
}
</style>